html {
    @apply tw-bg-white tw-h-full tw-text-[16px];
}

body {
    font-family: Poppins, sans-serif;
    -webkit-font-smoothing: antialiased;
    overscroll-behavior: none;
}

.CookiebotWidget-logo {
    width: auto;
}

p {
    @apply tw-mb-4;
}
