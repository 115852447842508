@font-face {
    font-family: Poppins;
    src: url('../assets/fonts/Poppins/Poppins-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url('../assets/fonts/Poppins/Poppins-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url('../assets/fonts/Poppins/Poppins-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url('../assets/fonts/Poppins/Poppins-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}
