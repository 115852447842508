/*
 * Container style
 */
.ps {
    overflow: hidden !important;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
}

/*
   * Scrollbar rail styles
   */
.ps__rail-x {
    display: none;
    opacity: 0.6;
    transition:
        background-color 0.2s linear,
        opacity 0.2s linear;
    height: 0.375rem;
    bottom: 0;
    position: absolute;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.ps__rail-y {
    display: none;
    opacity: 0.6;
    transition:
        background-color 0.2s linear,
        opacity 0.2s linear;
    width: 0.375rem;
    right: 0;
    position: absolute;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
    display: block;
    background-color: transparent;
}

.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y {
    opacity: 0.6;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    opacity: 0.9;
}

/*
   * Scrollbar thumb styles
   */
.ps__thumb-x {
    @apply tw-bg-gray-400;

    border-radius: 0.5rem;
    transition:
        background-color 0.2s linear,
        height 0.2s ease-in-out;
    height: 0.375rem;
    bottom: 0.375rem;
    position: absolute;
}

.ps__thumb-y {
    @apply tw-bg-gray-400;

    border-radius: 0.5rem;
    transition:
        background-color 0.2s linear,
        width 0.2s ease-in-out;
    width: 0.375rem;
    right: 0.375rem;
    position: absolute;
}

/* MS supports */
@supports (-ms-overflow-style: none) {
    .ps {
        overflow: auto !important;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .ps {
        overflow: auto !important;
    }
}
